import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, FeaturedPosts, LatestNews, BusinessTag, FoodTag, TravelTag, PoliticsTag, LifeTag } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
            <FeaturedPosts />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="latest-post-section post-group">
                                <div className="post-group-title">
                                    <span>Latest News</span>
                                </div>
                                <LatestNews data={posts} no="0" />
                                <div className="row">
                                    <LatestNews data={posts} no="1" />
                                </div>
                            </div>
                        </div>
                        <BusinessTag tag="business" xPostSection="1"></BusinessTag>
                    </div>
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
